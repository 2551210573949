import React from "react";
import "./heading.css";

const Heading = ({ number, text }) => {
  return (
    <>
      <div className="port__heading">
        <h2> {number}</h2>
        <h2>{text}</h2>
      </div>
    </>
  );
};

export default Heading;
