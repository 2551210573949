import "./App.css";
import { Header, About, Projects, Contact, Footer } from "./containers";

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Projects />

      <Contact />
      <Footer />
    </div>
  );
}

export default App;
