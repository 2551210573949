import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="port__footer">
      <p> Designed and Built by Brian Simiyu</p>
    </div>
  );
};

export default Footer;
