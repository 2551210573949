import React from "react";
import { Heading, ProjectCard } from "../../components";
import "./projects.css";
import gpt3 from "../../assets/gpt3.png";
import geritch from "../../assets/geritch.png";
import nightclub from "../../assets/nightclub.png";
import sneakers from "../../assets/sneakers.png";

const Projects = () => {
  return (
    <div className="port__projects" id="projects">
      <Heading number="0.2" text="Some Things I've Built" />

      <div className="port__projects-container">
        <ProjectCard
          projectName="GPT3 Website"
          projectImage={gpt3}
          projectLink="https://brian-simiyu-gpt3-porfolio-website.pages.dev/"
          projectDescription="A Responsive and modern UI/UX Website built with ReactJS and CSS."
        />

        <ProjectCard
          projectName="Restaurant Website"
          projectImage={geritch}
          projectLink="https://brian-simiyu-restaurant-portfolio-website.pages.dev/"
          projectDescription="A fully functioning, modern and responsive restaurant website built with ReactJS and CSS."
        />

        <ProjectCard
          projectName="E-commerce store"
          projectImage={sneakers}
          projectLink="https://brian-simiyu-sneakershop-portfolio-website.pages.dev/"
          projectDescription="Responsive ecommerce website project. Built with simple HTML, CSS and Javascript."
        />

        <ProjectCard
          projectName="Nightclub Website"
          projectImage={nightclub}
          projectLink="https://brian-simiyu-lax-nightclub-portfolio.pages.dev/"
          projectDescription="The first ReactJS project I ever built combined with some of my CSS knowledge."
        />
      </div>
    </div>
  );
};

export default Projects;
