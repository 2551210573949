import React, { useState } from "react";
import "./navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { CloseOutlined, MenuOutlined } from "@mui/icons-material";
import cv from "../../documents/cv.pdf";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="port__navbar">
      <div className="port__navbar-logo">
        <h1>
          <a href="#home">BS</a>
        </h1>
      </div>
      <div className="port__navbar-bigScreen">
        <div className="port__navbar-container">
          <div className="port__navbar-container_links">
            <p>
              <a href="#about">0.1</a>
            </p>
            <p>
              <a href="#about">About</a>
            </p>
          </div>
          <div className="port__navbar-container_links">
            <p>
              <a href="#projects">0.2</a>
            </p>
            <p>
              <a href="#projects">Projects</a>
            </p>
          </div>

          <div className="port__navbar-container_links">
            <p>
              <a href="#contact">0.3</a>
            </p>
            <p>
              <a href="#contact">Contact</a>
            </p>
          </div>
          <a href={cv} target="_blank">
            <button>Resume</button>
          </a>
        </div>
      </div>
      <div className="port__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay slide-bottom">
            <div>
              <CloseOutlined
                className="app__navbar-smallscreen-close"
                onClick={() => setToggleMenu(false)}
              />
            </div>
            <div className="port__navbar-container">
              <div className="port__navbar-container_links">
                <p>
                  <a href="#about">0.1</a>
                </p>
                <p>
                  <a href="#about">About</a>
                </p>
              </div>

              <div className="port__navbar-container_links">
                <p>
                  <a href="#projects">0.2</a>
                </p>
                <p>
                  <a href="#projects">Projects</a>
                </p>
              </div>

              <div className="port__navbar-container_links">
                <p>
                  <a href="#contact">0.3</a>
                </p>
                <p>
                  <a href="#contact">Contact</a>
                </p>
              </div>

              <a href={cv} target="_blank">
                <button>Resume</button>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
