import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "../../components";
import "./contact.css";

const Contact = () => {
  return (
    <div className="port__contact" id="contact">
      <div>
        <Heading number="0.4" text="Get in touch" />
      </div>
      <div className="port__contact-container">
        <p>
          I am currently looking a job/ workplace where I can grow, learn and
          develop my software development and design skills.
        </p>
        <a href="https://www.linkedin.com/in/brian-simiyu/" target="_blank">
          <button>Let's Connect</button>
        </a>
      </div>
    </div>
  );
};

export default Contact;
