import React from "react";
import { Heading } from "../../components";
import profilePic from "../../assets/profilePic.JPG";
import "./about.css";

const About = () => {
  return (
    <div className="port__about" id="about">
      <Heading number="0.1" text="About me" />
      <div className="port__about-container">
        <div className="port__about-container_content">
          <p>
            Hello! My name is Brian and I enjoy creating things that live on the
            internet. My interest in web development started in 2019 when I
            decided to build a landing page for my employer — turns out that
            taught me a lot about HTML & CSS!
          </p>

          <p>
            Fast-forward to January 2021, I had the privilege of joining
            Haaga-Helia University to studying software engineering. In school
            and personally, I have built several small and medium sized ReactJS
            sites.
          </p>

          <p>
            I am interested in good UX, clear communication and process, and
            making websites that are enjoyable to use and easy to maintain.
          </p>
        </div>
        <div className="port__about-profilePic">
          <img src={profilePic} alt="Profile Picture" />
        </div>
      </div>
    </div>
  );
};

export default About;
