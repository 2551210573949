import React from "react";
import "./projectCard.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ProjectCard = ({
  projectName,
  projectImage,
  projectLink,
  projectDescription,
}) => {
  return (
    <div className="port__projectCard">
      <Card
        className="port__projectCard-darkbg"
        style={{ backgroundColor: "#112240" }}
      >
        <CardMedia
          component="img"
          alt="green iguana"
          height="200"
          image={projectImage}
        />
        <CardContent
          className="port__projectCard-container"
          style={{
            color: "white",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {projectName}
          </Typography>
          <Typography variant="body2" color="white">
            {projectDescription}
          </Typography>
        </CardContent>

        <CardActions>
          <a className="port__projectCard-a" href={projectLink} target="_blank">
            <Button
              className="port__projectCard-btn"
              variant="contained"
              style={{
                color: "var(--green)",
                backgroundColor: "transparent",
                border: "1px solid var(--green)",
              }}
            >
              View project
            </Button>
          </a>
        </CardActions>
      </Card>
    </div>
  );
};

export default ProjectCard;
