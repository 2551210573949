import React from "react";
import { Navbar } from "../../components";
import "./header.css";

const Header = () => {
  return (
    <div className="port__header">
      <div className="port__header-navbar">
        <Navbar />
      </div>

      <div className="port__header-container">
        <div className="port__header-container_1">
          <h1>Hi, my name is</h1>
        </div>
        <div className="port__header-container_2">
          <h2>Brian Simiyu</h2>
        </div>
        <div className="port__header-container_3">
          <h3>I build things for the web</h3>
        </div>

        <div className="port__header-container_4">
          <p>
            I’m a software developer specializing in building (and occasionally
            designing) exceptional digital experiences. Currently, I’m focused
            on building accessible, human-centered products at Upstatement.
          </p>
        </div>
        <div className="port__header-container_button">
          <a href="https://www.linkedin.com/in/brian-simiyu/" target="_blank">
            <button>Let's Connect</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
